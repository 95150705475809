import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import mtiLogo from "../styles/images/mti_hirfelhasznalo.jpg"
import PageSeo from "../components/crumbs/pageSeo"

export default function Home() {
  // Go to the home page instead
  const refreshUrl = "/"

  return (
    <>
      <PageSeo
        title="Minden Percben | Impresszum"
        description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
        keywords="hírek, érdekességek"
        canonical="https://www.mindenpercben.hu"
        canonicalMobile = "https://m.mindenpercben.hu/"        
      />
      <LayoutSite 
        refreshUrl={refreshUrl}
        content={
          <>
            <div className="section retraction" style={{ display: `block` }}>
              <div className="wrap_news_block">
                <div className="container-fluid">
                  <div className="block-part">
                    <div className="row row-glairy">
                      <div className="col-glairy col-xs-12 col-md-8">
                        <div className="row row-glairy">
                          <div className="row row-glairy">
                            <div className="col-glairy col-xs-12 col-md-12">
                              <div className="content content-hu">
                                <div className="content-info">
                                  <div>
                                    <h1>Főszerkesztő, adatvédelmi tisztségviselő</h1>
                                    <div className="lead" style={{ marginTop: `30px` }}>Főszerkesztő: Kun Jeromos</div>
                                    <div className="lead">Adatvédelmi tisztségviselő: Kun Jeromos</div>
                                    <div className="text">Bármilyen kérdése, vagy észrevétele van, kérjük, forduljon hozzánk bizalommal:<br /><i><strong>mindenpercben kukac gmail pont com</strong></i></div>
                                    <div style={{ textAlign: `center`, display: `inline-block` }}>
                                      <img src={mtiLogo} alt="MTI Hírfelhasználó"></img>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}